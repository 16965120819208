<template>
  <b-card class="text-center">
    <b-spinner variant="primary" />
  </b-card>
</template>

<script>
import {
  BCard,
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'CardSpinner',
  components: {
    BCard,
    BSpinner,
  },
}
</script>

<style scoped>

</style>

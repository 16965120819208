<template>
  <b-card
    no-body
    class="overflow-hidden"
  >
    <b-card-body class="p-0">
      <div class="top-banner d-inline-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div>
            <h5>{{ $t('dashboard.yourSubscription') }}</h5>
            <h3 class="mb-0">
              {{ subscription.name }}
            </h3>
          </div>
        </div>
        <b-img
          src="@/assets/images/icons/subscription_badge.svg"
          style="width: 60px;"
        />
      </div>
      <div class="p-2">
        <h3 class="text-primary text-bold">
          {{ $t('dashboard.subscriptionBenefits') }}
        </h3>

        <b-list-group class="ml-2 list-group-circle text-left">
          <b-list-group-item
            v-for="(feature, index) in subscription.features"
            :key="index"
          >
            {{ feature }}
          </b-list-group-item>
        </b-list-group>

        <b-button
          v-if="!loadingSubscriptionManagement"
          class="mt-1"
          variant="outline-primary"
          @click="manageSubscription"
        >
          {{ $t('subscription.manageCTA') }}
        </b-button>
        <b-spinner
          v-else
          class="mt-1"
          variant="primary"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BImg,
  BListGroup,
  BListGroupItem,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'Subscription',
  components: {
    BCard,
    BCardBody,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
    BSpinner,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingSubscriptionManagement: false,
    }
  },
  methods: {
    manageSubscription() {
      this.loadingSubscriptionManagement = true

      this.$http.post('/user/stripe/manage-subscription', {
        returnUrl: window.location.href,
      })
        .then(response => {
          if (response.data != null && response.data.url != null) {
            window.location.href = response.data.url
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingSubscriptionManagement = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";

.top-banner {
  width: 100%;
  padding: 1.5rem;
  background-color: #C1D3DF;
}
</style>

<template>
  <b-container fluid>
    <!-- profile -->
    <b-spinner
      v-if="isLoading"
      variant="primary"
    />
    <b-alert
      v-else-if="loadError != null"
      variant="danger"
    >
      {{ loadError }}
    </b-alert>
    <validation-observer
      v-else-if="user != null"
      ref="profileForm"
    >
      <b-row>
        <b-col
          md="3"
        >
          <b-card class="text-center my-2">
            <b-avatar
              :text="avatarText"
              size="100px"
            />
            <h3 class="my-1">
              <strong>{{ user.alias }}</strong>
            </h3>
          </b-card>

          <card-spinner
            v-if="userSubscription == null"
          />
          <subscription
            v-else
            :subscription="userSubscription"
          />
        </b-col>
        <!-- profile information form -->
        <b-col
          md="9"
          class="px-2 py-2"
        >
          <b-card :title="$t('profile.personalInformation')">
            <b-form
              id="profile-form"
              class="mt-2"
            >
              <!-- profile name & upload picture row -->
              <b-form-row class="my-2">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.profileNameLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.profileNameLabel')} *`">
                      <b-form-input
                        v-model="user.alias"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <!-- first / middle / last name -->
              <b-form-row class="my-2">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.firstNameLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.firstNameLabel')} *`">
                      <b-form-input
                        v-model="user.firstName"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-form-group :label="$t('profile.middleNameLabel')">
                    <b-form-input
                      v-model="user.middleName"
                      :disabled="!isEditing"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.lastNameLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.lastNameLabel')} *`">
                      <b-form-input
                        v-model="user.lastName"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <!-- pronouns / language/ phone number -->
              <b-form-row class="my-2">
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.pronounsLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.pronounsLabel')} *`">
                      <v-select
                        v-model="user.preferredPronoun"
                        :options="pronounOptions"
                        label="displayText"
                        :reduce="pronoun => pronoun.value"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.languageLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.languageLabel')} *`">
                      <v-select
                        v-model="user.language"
                        :options="languageOptions"
                        label="displayText"
                        :reduce="language => language.value"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-form-group :label="$t('profile.phoneNumberLabel')">
                    <b-form-input
                      v-model="user.phoneNumber"
                      :disabled="!isEditing"
                      type="tel"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
              <!-- email / time zone row -->
              <b-form-row>
                <b-col
                  md="7"
                >
                  <b-form-group :label="`${$t('profile.emailLabel')} *`">
                    <b-form-input
                      v-model="user.email"
                      type="email"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="5"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.timeZoneLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.timeZoneLabel')} *`">
                      <v-select
                        v-model="user.localTimeZone"
                        :options="timezoneOptions"
                        label="displayText"
                        :reduce="zone => zone.value"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <!-- address -->
              <b-form-row>
                <b-col
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.addressLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.addressLabel')} *`">
                      <b-form-input
                        v-model="user.address.address"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.addressCityLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.addressCityLabel')} *`">
                      <b-form-input
                        v-model="user.address.city"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.addressStateLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.addressStateLabel')} *`">
                      <v-select
                        v-model="user.address.state"
                        :options="stateOptions"
                        label="displayText"
                        :reduce="state => state.value"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.addressZipCodeLabel')"
                    rules="required"
                  >
                    <b-form-group :label="`${$t('profile.addressZipCodeLabel')} *`">
                      <b-form-input
                        v-model="user.address.zipCode"
                        :disabled="!isEditing"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <!-- edit button -->
              <b-button
                v-if="!isEditing"
                variant="primary"
                size="lg"
                class="mt-1 px-4"
                @click="edit"
              >
                {{ $t('profile.editAction') }}
              </b-button>
              <!-- save / discard buttons -->
              <div
                v-else
                class="mt-1"
              >
                <b-button
                  variant="outline-secondary"
                  class="mr-1"
                  size="lg"
                  @click="discard"
                >
                  {{ $t('profile.discardAction') }}
                </b-button>
                <b-button
                  variant="primary"
                  size="lg"
                  @click="save"
                >
                  {{ $t('profile.saveAction') }}
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </b-container>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BContainer,
  BAvatar,
  BForm,
  BFormGroup,
  BFormRow,
  BFormInput,
  BAlert,
  BSpinner,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import CardSpinner from '@/components/CardSpinner.vue'
import store from '@/store'
import Subscription from '@/components/Subscription.vue'

export default {
  name: 'Profile',
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BContainer,
    BAvatar,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BAlert,
    BSpinner,
    CardSpinner,
    vSelect,
    ToastificationContent,
    ValidationObserver,
    ValidationProvider,
    Subscription,
  },
  data() {
    return {
      isLoading: true,
      user: null,
      loadError: null,
      attributeOptions: [],
      isEditing: false,
      userPricingPlanIndex: null,
      isLoadingPricingPlans: false,
      pricingPlans: [],
      required,
    }
  },
  computed: {
    avatarText() {
      const { firstName, lastName } = this.user

      if (firstName != null && firstName.length > 0 && lastName != null && lastName.length > 0) {
        return firstName.charAt(0) + lastName.charAt(0)
      }

      if (firstName != null && firstName.length > 0) {
        return firstName.charAt(0)
      }

      if (lastName != null && lastName.length > 0) {
        return lastName.charAt(0)
      }

      return ''
    },
    languageOptions() {
      const optionIndex = this.attributeOptions.findIndex(o => o.name === 'language')
      return this.attributeOptions[optionIndex].options
    },
    pronounOptions() {
      const optionIndex = this.attributeOptions.findIndex(o => o.name === 'preferredPronoun')
      return this.attributeOptions[optionIndex].options
    },
    timezoneOptions() {
      const optionIndex = this.attributeOptions.findIndex(o => o.name === 'localTimeZone')
      return this.attributeOptions[optionIndex].options
    },
    stateOptions() {
      const optionIndex = this.attributeOptions.findIndex(o => o.name === 'state')
      return this.attributeOptions[optionIndex].options
    },
    userSubscription() {
      if (this.pricingPlans == null
        || this.pricingPlans.length === 0
        || this.userPricingPlanIndex == null
        || this.pricingPlans.length <= this.userPricingPlanIndex) {
        return null
      }

      return this.pricingPlans[this.userPricingPlanIndex]
    },
    dashboardInfo() {
      return store.getters['app/dashboard']
    },
  },
  watch: {
    dashboardInfo(newVal) {
      if (newVal != null
        && newVal.validSubscription
        && newVal.subscriptionLevel != null
        && newVal.subscriptionLevel.length === 4
        && newVal.subscriptionLevel !== 'None') {
        const level = newVal.subscriptionLevel
        if (level.startsWith('D2C')) {
          const pricingPlanIndexPart = level.substring(3)
          const pricingPlanIndex = Number(pricingPlanIndexPart)
          if (!Number.isNaN(pricingPlanIndex)) {
            this.userPricingPlanIndex = pricingPlanIndex - 1
          }
        }
      }
    },
  },
  mounted() {
    store.dispatch('app/fetchDashboard')
    this.fetchPricingPlans()

    this.$http.get('/user/profile?include-options=true')
      .then(response => {
        this.user = response.data.userProfile
        this.attributeOptions = response.data.attributeOptions
        this.loadError = null
      })
      .catch(() => {
        this.loadError = this.$t('generic.apiError')
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    edit() {
      this.isEditing = true
    },
    discard() {
      this.isEditing = false
    },
    fetchPricingPlans() {
      this.isLoadingPricingPlans = true

      this.$http.get('/subscriptions')
        .then(response => {
          this.pricingPlans = response.data
        })
        .finally(() => {
          this.isLoadingPricingPlans = false
        })
    },
    save() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          this.isEditing = false

          this.$http.put('/user/profile', this.user)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('generic.success'),
                  icon: 'CheckCircleIcon',
                  text: this.$t('profile.saveSuccessful'),
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              this.loadError = this.$t('generic.apiError')
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/libs/vue-select.scss';
</style>

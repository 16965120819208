var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"variant":"primary"}}):(_vm.loadError != null)?_c('b-alert',{attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.loadError)+" ")]):(_vm.user != null)?_c('validation-observer',{ref:"profileForm"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-card',{staticClass:"text-center my-2"},[_c('b-avatar',{attrs:{"text":_vm.avatarText,"size":"100px"}}),_c('h3',{staticClass:"my-1"},[_c('strong',[_vm._v(_vm._s(_vm.user.alias))])])],1),(_vm.userSubscription == null)?_c('card-spinner'):_c('subscription',{attrs:{"subscription":_vm.userSubscription}})],1),_c('b-col',{staticClass:"px-2 py-2",attrs:{"md":"9"}},[_c('b-card',{attrs:{"title":_vm.$t('profile.personalInformation')}},[_c('b-form',{staticClass:"mt-2",attrs:{"id":"profile-form"}},[_c('b-form-row',{staticClass:"my-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('profile.profileNameLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.profileNameLabel')) + " *")}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.alias),callback:function ($$v) {_vm.$set(_vm.user, "alias", $$v)},expression:"user.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-form-row',{staticClass:"my-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('profile.firstNameLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.firstNameLabel')) + " *")}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('profile.middleNameLabel')}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.middleName),callback:function ($$v) {_vm.$set(_vm.user, "middleName", $$v)},expression:"user.middleName"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('profile.lastNameLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.lastNameLabel')) + " *")}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-form-row',{staticClass:"my-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('profile.pronounsLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.pronounsLabel')) + " *")}},[_c('v-select',{attrs:{"options":_vm.pronounOptions,"label":"displayText","reduce":function (pronoun) { return pronoun.value; },"disabled":!_vm.isEditing},model:{value:(_vm.user.preferredPronoun),callback:function ($$v) {_vm.$set(_vm.user, "preferredPronoun", $$v)},expression:"user.preferredPronoun"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('profile.languageLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.languageLabel')) + " *")}},[_c('v-select',{attrs:{"options":_vm.languageOptions,"label":"displayText","reduce":function (language) { return language.value; },"disabled":!_vm.isEditing},model:{value:(_vm.user.language),callback:function ($$v) {_vm.$set(_vm.user, "language", $$v)},expression:"user.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('profile.phoneNumberLabel')}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing,"type":"tel"},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"md":"7"}},[_c('b-form-group',{attrs:{"label":((_vm.$t('profile.emailLabel')) + " *")}},[_c('b-form-input',{attrs:{"type":"email","disabled":true},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.timeZoneLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.timeZoneLabel')) + " *")}},[_c('v-select',{attrs:{"options":_vm.timezoneOptions,"label":"displayText","reduce":function (zone) { return zone.value; },"disabled":!_vm.isEditing},model:{value:(_vm.user.localTimeZone),callback:function ($$v) {_vm.$set(_vm.user, "localTimeZone", $$v)},expression:"user.localTimeZone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.addressLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.addressLabel')) + " *")}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.address.address),callback:function ($$v) {_vm.$set(_vm.user.address, "address", $$v)},expression:"user.address.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.addressCityLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.addressCityLabel')) + " *")}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.address.city),callback:function ($$v) {_vm.$set(_vm.user.address, "city", $$v)},expression:"user.address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.addressStateLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.addressStateLabel')) + " *")}},[_c('v-select',{attrs:{"options":_vm.stateOptions,"label":"displayText","reduce":function (state) { return state.value; },"disabled":!_vm.isEditing},model:{value:(_vm.user.address.state),callback:function ($$v) {_vm.$set(_vm.user.address, "state", $$v)},expression:"user.address.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.addressZipCodeLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('profile.addressZipCodeLabel')) + " *")}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEditing},model:{value:(_vm.user.address.zipCode),callback:function ($$v) {_vm.$set(_vm.user.address, "zipCode", $$v)},expression:"user.address.zipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(!_vm.isEditing)?_c('b-button',{staticClass:"mt-1 px-4",attrs:{"variant":"primary","size":"lg"},on:{"click":_vm.edit}},[_vm._v(" "+_vm._s(_vm.$t('profile.editAction'))+" ")]):_c('div',{staticClass:"mt-1"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-secondary","size":"lg"},on:{"click":_vm.discard}},[_vm._v(" "+_vm._s(_vm.$t('profile.discardAction'))+" ")]),_c('b-button',{attrs:{"variant":"primary","size":"lg"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('profile.saveAction'))+" ")])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }